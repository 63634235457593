export function addCookie(c_name, value, expiredays) { //添加cookie
  // 以小时计算cookie的消失时间
  // var str = objName + "=" + escape(objValue);
  //为0时不设定过期时间，浏览器关闭时cookie自动消失
  // if (objHours > 0) {
  //   var date = new Date();
  //   var ms = objHours * 3600 * 1000;
  //   console.log(ms);
  //   date.setTime(date.getTime() + ms);
  //   str += "; expires=" + date.toGMTString();
  // }
  // document.cookie = str;
  // 以天计算cookie的消失时间
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  var x = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
  // $.cookie('clientId', 111, { expires: x, path: '/' });
  if (expiredays==0) {
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + x);
  } else{
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
  }
  //alert("添加cookie成功");
}

//获取指定名称的cookie的值
export function getCookie(objName) {
  var arrStr = document.cookie.split("; ");
  for (var i = 0; i < arrStr.length; i++) {
    var temp = arrStr[i].split("=");
    if (temp[0] == objName)
      return unescape(temp[1]);
  }
}

//为了删除指定名称的cookie，可以将其过期时间设定为一个过去的时间
export function delCookie(name) {
  var date = new Date();
  date.setTime(date.getTime() - 10000);
  document.cookie = name + "=a; expires=" + date.toGMTString();
}

//读取所有保存的cookie字符串
export  function allCookie() {
  var str = document.cookie;
  if (str == "") {
    str = "没有保存任何cookie";
  }
  alert(str);
}
