<template>
  <div class="product">
    <div class="product-list">
      <div class="title">
        {{ title }}
      </div>
      <div class="product-main">
        <div class="product-text">
          <p>
            <em>Last updated: Aug 25, 2023</em>
          </p>
          <p>
            <em><br /></em>
          </p>
          <p>
            THESE TERMS FORM A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US.
            PLEASE TAKE THE TIME TO READ THEM CAREFULLY. IF YOU DO NOT AGREE TO
            THESE TERMS, DO NOT PLAY THE GAME OR ACCESS THE SERVICE.
          </p>
          <p>
            <br />
          </p>
          <p>
            PLEASE NOTE THAT DIFFERENT TERMS MAY APPLY TO YOU BASED ON WHERE YOU
            LIVE. MAKE SURE YOU READ THE JURISDICTION-SPECIFIC TERMS FOR YOUR
            JURISDICTION OF RESIDENCE (AT THE BOTTOM OF THESE TERMS). THESE
            SPECIFIC TERMS CONCERN IMPORTANT ASPECTS OF OUR AGREEMENT,
            INCLUDING, WITHOUT LIMITATION, HOW YOU CONSENT TO THESE TERMS, YOUR
            CONSUMER RIGHTS (INCLUDING YOUR CANCELLATION RIGHT, IF APPLICABLE),
            OUR RIGHTS TO TERMINATE YOUR ACCOUNT, YOUR RIGHT TO RECEIVE REFUNDS
            IN CERTAIN CASES, OUR WARRANTIES AND LIABILITY, AND THE LAW
            APPLICABLE TO THESE TERMS. THERE ARE CURRENTLY JURISDICTION-SPECIFIC
            TERMS FOR THE EUROPEAN UNION IN GENERAL AS WELL AS GERMANY, THE UK,
            AUSTRALIA, CANADA, NEW ZEALAND, AND US.
          </p>
          <p>
            ARBITRATION NOTICE FOR USERS: THESE TERMS CONTAIN A MUTUAL AGREEMENT
            TO ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS. EXCEPT FOR
            CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION AGREEMENT,
            YOU AND FunCat AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY
            BINDING ARBITRATION, AND YOU AND FunCat WAIVE ANY RIGHT TO
            PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
          </p>
          <br />
          <p><strong>Your Relationship with Us</strong></p>
          <br />
          <p>
            Welcome to <em><strong>Tokyo Ghoul: Break the Chains</strong></em
            ><strong> </strong>(the &quot;Game&quot;), which is provided by
            FunCat Games Limited and/or one of its affiliates
            (&quot;FunCat&quot;, &quot;we&quot; or &quot;us&quot;).
          </p>
          <p>
            You are reading the terms of service (the &quot;Terms&quot;), which
            govern the relationship and serve as a legally binding agreement
            between you and us and set forth the terms and conditions by which
            you may access and use the Game, the mobile application, the
            software imbedded therein, the features and functions thereof and
            our related websites, services, applications, products and content
            (collectively, the &quot;Services&quot;). Any reference to the
            &quot;Services&quot; includes a reference to any part of the
            Services. For purposes of these Terms, &quot;you&quot; and
            &quot;your&quot; means you as the user of the Services. Our Services
            are provided to you only for private, non-commercial use.
          </p>
          <br />
          <p><strong>Age and Accepting the Terms</strong></p>
          <br />

          <p>
            Access to certain Services or features of the Services may be
            subject to age restrictions and not available to all users of the
            Services. The Services are only for users 13 years old and over
            (with additional limits that may be set forth in the Supplemental
            Terms – Jurisdiction-Specific). By accessing or using our Services,
            you confirm that you are over 13 and can form a binding contract
            with FunCat, that you accept these Terms and that you agree to
            comply with them. You understand and agree that we will treat your
            access or use of the Services as acceptance of the Terms from that
            point onwards.
          </p>
          <p>
            If you are over 13 but under the legal age to enter into a contract
            in your jurisdiction, your parents or legal guardian must read and
            agree to these Terms. If you do not have consent from your parents
            or legal guardians, you must cease accessing or using the Services.
            If we learn that someone under the relevant age is using the
            Services, we will terminate access to the Service for such user.
          </p>
          <p>
            You should print off or save a local copy of the Terms for your
            records.
          </p>
          <br />
          <p><strong> Supplemental Terms for Specific Jurisdictions</strong></p>
          <br />
          <p>
            If you access or use the Services from within a jurisdiction for
            which there are separate supplemental terms, you also agree to the
            supplemental terms applicable to you in the applicable jurisdiction
            as outlined below. If there is a conflict between the provisions of
            the Supplemental Terms – Jurisdiction-Specific that are applicable
            to you, and the rest of these Terms, the relevant jurisdictions’
            Supplemental Terms – Jurisdiction-Specific will supersede and
            control.
          </p>
          <br />
          <p><strong>Changes to the Terms</strong></p>
          <br />

          <p>
            We amend these Terms from time to time, to reflect changes to the
            Services, our users&#39; needs, our business priorities or to
            reflect changes in law. We will notify all users of any changes to
            these Terms, such as through a notice in our Game. However, you
            should also look at the Terms regularly to check for such changes.
            We will also update the &quot;Last Updated&quot; date at the top of
            these Terms, which reflect the effective date of such Terms. Your
            continued access or use of the Services after the date of the new
            Terms constitutes your acceptance of the new Terms. If you do not
            agree to the new Terms, you must stop accessing or using the
            Services.
          </p>
          <br />
          <p><strong>Your Account with Us</strong></p>
          <br />

          <p>
            You may access the Services without an account with us as a guest,
            in which case you may have limited access to certain features of the
            Services. Please note that if you are using a guest or visitor
            account, you will be unable to transfer/keep your progress or other
            information and data in the Game or restore any in-app purchases you
            have made if you need to re-install the Game, <em>e.g.</em>, if you
            switch your device. All information and data in connection with your
            use of the Game and Service will be removed and will not be
            retrievable or recoverable, if you delete the Game from your device.
          </p>
          <p>
            In some cases, you may use a third party account (<em>e.g</em>.,
            Apple, Facebook, Google, as applicable) (&quot;Third Party
            Account&quot;) to access additional features and functions of the
            Services. You agree that you are solely responsible (to us and to
            others) for your access and use of the Services and the activity
            that occurs under your account.
          </p>
          <p>
            It is important that you keep your account password confidential and
            that you do not disclose it to any third party. You acknowledge and
            agree that we have no obligations related to your Third Party
            Account, and all matters relating to any Third Party Account of
            yours shall be resolved exclusively by you and the relevant third
            party.
          </p>
          <p>
            We reserve the right to suspend or terminate your access to the
            Services at any time, without any notice to you, including if you
            have failed to comply with any of the provisions of these Terms, or
            if activities occur on your account which would or might cause
            damage to or impair the Services or infringe or violate any third
            party rights (including intellectual property rights), or violate
            any applicable laws or regulations. Termination of your account also
            entails the termination of the license to use the Service, or any
            part thereof.
          </p>
          <br />
          <p><strong>Virtual Items/Additional Content</strong></p>
          <br />

          <p>
            During your use of the Service, you may acquire, by purchasing with
            real-world currency or otherwise, virtual currency, points, coins,
            tokens, virtual in-Game items (including without limitation
            downloadable content), achievements, progressions, or access to
            additional features, level, characters, maps or other content
            (collectively, &quot;Virtual Items&quot;). Such Virtual can only be
            used for your own benefit within the Game, are of no monetary value
            and cannot be redeemed for cash or in-kind, sold or otherwise
            transferred. All Virtual Items are licensed to you rather than sold
            to you. You do not own or otherwise have proprietary rights of such
            Virtual Items. We will provide you the access to the Virtual Items
            for a period of at least one (1) month from the date of purchase,
            after which, we may, in our sole direction at anytime, terminate the
            Services under the Terms.
          </p>
          <p>
            All Virtual Items will be deleted or removed from your account when
            your account is terminated, and will not be retrievable, recoverable
            or refundable.
          </p>
          <br />
          <p><strong>Fees and Refund</strong></p>
          <br />

          <p>
            In some cases, certain portions of the Game may be accessed for
            free, but in other cases you may be required to pay a fee to access
            certain features of the Game. If you purchase any paid Virtual
            Items, you shall make a purchase through the Apple App Store, Google
            Play store, or other third party distribution platforms and official
            platforms we recognize in our sole discretion. You agree to provide
            accurate and complete payment information to Apple, Google or other
            third party distribution platforms and official platforms. You
            further agree to pay all fees and taxes incurred by your account. We
            reserve the right to change the pricing of any paid Virtual Items
            from time to time as we may determine in our sole discretion. Any
            change will not affect Virtual Items you have already purchased at
            the time the change comes into effect.
          </p>
          <p>
            Sales are final and we will not provide a refund unless otherwise
            required by applicable law. You must follow the applicable refund
            policy of Apple,Google or other third party distribution platforms
            and official platforms. Your purchase will be subject to the
            applicable payment policy of Apple,Google or other third party
            distribution platforms and official platforms. We may, but is not
            obligated to assist you with your refund application to Apple,
            Google or other third party distribution platforms and official
            platforms.
          </p>
          <br />
          <p><strong>Limited License</strong></p>
          <br />

          <p>
            As between you and FunCat, FunCat owns any and all rights, title and
            interest in and to the Services and any and all data and content
            made available in and through the Services, including without
            limitation all software, computer code, tools, patches, updates,
            images, text, graphics, illustrations, logos, photographs, images,
            pictures, audio, sound effect, sound recording, videos, visual
            effect, music, music composition, user account, characters,
            character name, character profile, character likeness, artifacts,
            objects, inventories, weapons, map, location, location name,
            architecture, landscape design, story, storyline, theme, game play,
            catch phrase, dialogue, chat log, recording and broadcasts of game,
            matches, battles, duels, in-game items, in-game activities, the
            selection and arrangement thereof and &quot;look and feel&quot; of
            the Services, and all intellectual property rights related thereto
            (the &quot;FunCat Content&quot;).
          </p>
          <p>
            You acknowledge that your use of the FunCat Content for any purpose
            not expressly permitted by these Terms is strictly prohibited.
            FunCat Content may not be downloaded, copied, reproduced,
            distributed, transmitted, broadcast, displayed, sold, licensed or
            otherwise exploited for any purpose whatsoever without our or, where
            applicable, our licensors’ prior written consent. You cannot create
            any work of authorship or proprietary right based on the Services
            (including FunCat Content). You shall only use the Services as may
            be expressly provided in the Terms or to the extent permitted by law
            or (if applicable) relevant open source licenses. We and our
            licensors reserve all rights not expressly granted in and to their
            content.
          </p>
          <p>
            Subject to these Terms, you are hereby granted a non-exclusive,
            personal, non-transferable, non-sublicensable, revocable limited
            license to access and use the Services, including to download the
            Game on a permitted device, and to access the FunCat Content solely
            for your personal, non-commercial use through your use of the
            Services and solely in compliance with these Terms. FunCat reserves
            all rights not expressly granted herein in the Services (including
            FunCat Content). You acknowledge and agree that FunCat may terminate
            this license at any time for convenience subject to applicable law.
          </p>
          <p>
            Where our Services may contain links to other sites and resources
            provided by third parties, these links are provided for your
            information only. We have no control over the contents of those
            sites or resources. Such links should not be interpreted as approval
            or endorsement by us of those linked websites or information you may
            obtain from them.
          </p>
          <br />
          <p><strong>Feedback</strong></p>
          <br />

          <p>
            While we are continually working to develop and evaluate our own
            product ideas and features, we pride ourselves on paying close
            attention to the interests, feedback, comments, and suggestions we
            receive from the user community. If you choose to contribute by
            sending us any ideas for products, services, features,
            modifications, enhancements, content, refinements, technologies,
            content offerings (such as audio, visual or other types of content),
            promotions, strategies, or product/feature names, or any related
            documentation, artwork, computer code, diagrams, or other materials
            (collectively &quot;Feedback&quot;), then regardless of what your
            accompanying communication may say, the following terms will apply,
            so that future misunderstandings can be avoided. Accordingly, by
            sending Feedback to us, you agree that:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                FunCat has no obligation to review, consider, or implement your
                Feedback, or to return to you all or part of any Feedback for
                any reason;
              </p>
            </li>
            <li>
              <p>
                Feedback is provided on a non-confidential basis, and we are not
                under any obligation to keep any Feedback you send confidential
                or to refrain from using or disclosing it in any way; and
              </p>
            </li>
            <li>
              <p>
                You irrevocably grant us perpetual and unlimited permission to
                reproduce, distribute, create derivative works of, modify,
                publicly perform (including on a through-to-the-audience basis),
                communicate to the public, make available, publicly display, and
                otherwise use and exploit the Feedback and derivatives thereof
                for any purpose and without restriction, free of charge and
                without attribution of any kind, including by making, using,
                selling, offering for sale, importing, and promoting commercial
                products and services that incorporate or embody Feedback,
                whether in whole or in part, and whether as provided or as
                modified.
              </p>
            </li>
          </ul>

          <br />
          <p><strong>Equipment Requirement</strong></p>
          <br />

          <p>
            To use the Services, you may need certain hardware, software,
            capabilities and other resources (including a suitable connection to
            the Internet). You are solely responsible for procuring and
            maintaining such resources at your own cost.
          </p>

          <br />
          <p>
            <strong>
              Beta Version, Updates, Interruption and Termination of the
              Game</strong
            >
          </p>
          <br />
          <p>
            We may make available a beta version of the Game (&quot;Beta
            Game&quot;) prior to an official release of the Game. To the maximum
            extent permitted by applicable law, the Beta Game is provided for
            testing on an &quot;as is&quot; and &quot;as available&quot; basis
            and we make no warranty, expressly or by implication, to you of any
            kind. You understand and agree that the Beta Game may not operate
            correctly, the Beta Game may contain defects, and playing the Beta
            Game is at your own risk. We have no obligations to you with respect
            to Beta Game, including without limitation any obligation to provide
            an official version of the Game in the future.
          </p>
          <p>
            We may reset the Beta Game, including any in-Game setting (e.g.,
            game character, achievements and inventory items) at anytime during
            the testing period of the Beta Game. You acknowledge that the change
            or modification of the Beta Game may cause a loss of data, function
            or utility related to the Beta Game. You agree that we are not
            liable to you for such loss to the maximum extent permitted by
            applicable law. All Virtual Items (including downloadable content)
            you obtained during your use of the Beta Game will be removed from
            your account when the Beta Game is reset.
          </p>
          <p>
            We may collect additional personal data through the Beta Game that
            may not be collected in the official release. This personal data may
            include information about your device and network, account
            information and user-generated content, your use of the Beta Game,
            feedback you provide to us, and other data described in our Privacy
            Policy. This personal data may be used for improving the Beta Game
            or any other purpose described in our Privacy Policy.
          </p>
          <p>
            From time to time, we have the right (but not obligation) to provide
            updates (patches, new features, etc.) to the Game for free or for a
            fee. We may require you to download and install the updates or the
            updated version of the Game to continue to use the Services. You
            understand that your access to certain features of the Services
            might be affected by such updates. You also agree that we may use
            background patching to automatically update our Game and software
            with or without notice to you. You also understand that such updates
            may affect the necessary system specification required to play the
            Game. In such case, you are responsible for any necessary equipment
            to continue to access the Services.
          </p>
          <p>
            You acknowledge that the Service or any part thereof may be
            interrupted for maintenance or reasons beyond our control, and we
            cannot guarantee that the Services will not be interrupted. We shall
            not be liable for any interruption of the Services, delay or failure
            to perform resulting from any causes whatsoever. Additionally, the
            Service may be unavailable depending on geographic location.
          </p>
          <p>
            To the maximum extent permitted by applicable law, we reserve the
            right to discontinue the Service at any time in our sole discretion,
            for any reason, or for no reason, with or without notice; provided,
            however, that in the event that we terminate your access to the Game
            because we no longer have the right to provide it in connection with
            the Services, we will work in good faith to promptly transition your
            account and rights to a third-party provider of services that
            succeeds to the rights in the Game held by us.
          </p>
          <br />
          <p><strong> Code of Conduct</strong></p>
          <br />

          <p>
            Your access to and use of the Services is subject to these Terms and
            all applicable laws and regulations. You may not:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                access or use the Services if you are not fully able and legally
                competent to agree to these Terms;
              </p>
            </li>
            <li>
              <p>
                except as expressly permitted by these Terms or applicable law,
                make unauthorized copies, modify, adapt, translate, reverse
                engineer, disassemble, decompile or create any derivative works
                of the Services or any content included therein, including any
                software, Virtual Items (or any portion thereof) or determine or
                attempt to determine any source code, algorithms, methods or
                techniques embodied by the Services or any derivative works
                thereof;
              </p>
            </li>
            <li>
              <p>
                distribute, license, transfer, or sell, in whole or in part, any
                of the Services or any derivative works thereof;
              </p>
            </li>
            <li>
              <p>
                market, rent or lease the Services for a fee or charge, or use
                the Services to advertise or perform any commercial
                solicitation;
              </p>
            </li>
            <li>
              <p>
                use the Services, without our express written consent, for any
                commercial, political or unauthorized purpose, including
                communicating or facilitating any commercial advertisement or
                solicitation or spamming;
              </p>
            </li>
            <li>
              <p>
                interfere with or attempt to interfere with the proper working
                of the Services, disrupt our website (if any) or any networks
                connected to the Services, or bypass any measures we may use to
                prevent or restrict access to the Services;
              </p>
            </li>
            <li>
              <p>
                incorporate the Services or any portion thereof into any other
                program or product;
              </p>
            </li>
            <li>
              <p>
                use automated scripts, software, code or systems to collect
                information from or otherwise interact with the Services;
              </p>
            </li>
            <li>
              <p>
                impersonate any person or entity, or falsely state or otherwise
                misrepresent you or your affiliation with any person or entity,
                including giving the impression that any content you upload,
                post, transmit, distribute or otherwise make available emanates
                from the Services;
              </p>
            </li>
            <li>
              <p>
                intimidate or harass another, or promote sexually explicit
                material, violence or discrimination based on race, sex,
                religion, nationality, disability, sexual orientation or age;
              </p>
            </li>
            <li>
              <p>
                use or attempt to use another’s account, service or system
                without authorization from FunCat;
              </p>
            </li>
            <li>
              <p>
                use the Services in a manner that may create a conflict of
                interest or undermine the purposes of the Services;
              </p>
            </li>
            <li>
              <p>
                use or exploit any cheats, bots, hacks, bugs, errors, or design
                flaws to obtain unauthorized access to the Service or to gain an
                unfair advantage in game play;
              </p>
            </li>
            <li>
              <p>
                trade, sell, duplicate Virtual Items in or outside the Game
                unless otherwise expressly permitted by FunCat;
              </p>
            </li>
            <li>
              <p>
                host, provide or develop matchmaking services for the Game,
                unless otherwise expressly authorized by FunCat;
              </p>
            </li>
            <li>
              <p>
                use the Services to upload, transmit, distribute, store or
                otherwise make available in any way: files that contain viruses,
                trojans, worms, logic bombs or other material that is malicious
                or technologically harmful; any unsolicited or unauthorized
                advertising, solicitations, promotional materials, &quot;junk
                mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
                &quot;pyramid schemes,&quot; or any other prohibited form of
                solicitation; any private information of any third party,
                including addresses, phone numbers, email addresses, number and
                feature in the personal identity document (e.g., National
                Insurance, social security numbers, passport numbers) or credit
                card numbers; any material which does or may infringe any
                copyright, trade mark or other intellectual property or privacy
                rights of any other person; any material which is defamatory of
                any person, obscene, offensive, pornographic, hateful or
                inflammatory; any material that would constitute, encourage or
                provide instructions for a criminal offence, dangerous
                activities or self-harm; any material that is deliberately
                designed to provoke or antagonize people, especially trolling
                and bullying, or is intended to harass, harm, hurt, scare,
                distress, embarrass or upset people; any material that contains
                a threat of any kind, including threats of physical violence;
                any material that is racist or discriminatory, including
                discrimination on the basis of someone’s race, religion, age,
                gender, disability or sexuality;
              </p>
            </li>
            <ul
              class="list-bullet2 list-paddingleft-2"
              style="list-style-type: square"
            >
              <li>
                <p>
                  any answers, responses, comments, opinions, analysis or
                  recommendations that you are not properly licensed or
                  otherwise qualified to provide; or
                </p>
              </li>
            </ul>
          </ul>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                material that restricts or inhibits any other person from using
                the Services, or which may expose FunCat, the Services or its
                users to any harm or liability of any type.
              </p>
            </li>
          </ul>
          <p>
            We reserve the right, at any time and without prior notice, to
            remove or disable your access to the Services, including the Game,
            FunCat Content and Virtual Items, at our discretion for any reason
            or no reason. Some of the reasons for which we may remove or disable
            your access to the Services may include finding that you have
            violated these Terms, or that your actions are harmful to the
            Services or our users. Notwithstanding the foregoing, in the event
            that we terminate your access to the Game because we no longer have
            the right to provide it in connection with the Services, we will
            work in good faith to promptly transition your account to a
            third-party provider of services that succeeds to the rights in the
            Game.
          </p>

          <br />
          <p><strong> Indemnity</strong></p>
          <br />

          <p>
            You agree to, at your sole cost, defend, indemnify, and hold
            harmless FunCat, its parents, subsidiaries, and affiliates, and each
            of their respective officers, directors, employees, agents,
            suppliers and advisors from and against any and all claims,
            liabilities, costs, fines, penalties and expenses, including, but
            not limited to, attorneys’ fees and expenses, arising out of or in
            any way connected with any of the following: (i) a breach by you or
            any user of your account of any applicable obligation,
            representation or warranty under these Terms; (ii) our use, non-use
            or publication of your Feedback infringes any third party
            intellectual property rights; (iii) your access to or use of,
            activities in connection with, or the actual or threatened
            termination of your access to the Services; (iv) your violation of
            any applicable laws, rules, regulations or contracts; or (v) any
            misrepresentation made by you (all of the foregoing, &quot;Claims
            and Losses&quot;). You will cooperate as fully required by us in the
            defense of any Claims and Losses. Notwithstanding the foregoing, we
            retain the exclusive right to settle, compromise, and pay any and
            all Claims and Losses. We reserve the right to assume the exclusive
            defense and control of any Claims and Losses at our own cost. You
            will not settle any Claims and Losses without, in each instance, the
            prior written consent of an officer of the indemnified party.
          </p>
          <br />
          <p><strong>EXCLUSION OF WARRANTIES</strong></p>
          <br />

          <p>
            CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
            OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. NOTHING IN THESE
            TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CAN NOT
            CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS
            ENTITLED TO AS A CONSUMER.
          </p>
          <p>
            THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND WE MAKE NO WARRANTY
            OR REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO
            NOT REPRESENT OR WARRANT TO YOU THAT:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</p>
            </li>
            <li>
              <p>
                YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE
                OR FREE FROM ERROR;
              </p>
            </li>
            <li>
              <p>
                ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
                SERVICES WILL BE ACCURATE OR RELIABLE; OR
              </p>
            </li>
            <li>
              <p>
                DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE
                PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
              </p>
            </li>
          </ul>
          <p>
            NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED
            TERMS AS TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE
            WITH DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT
            THEY ARE EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND,
            WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR GAME
            FOR BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE.
          </p>
          <p>
            YOUR USE OF THE SERVICE MAY DEPEND ON THE INTERNET, INCLUDING
            NETWORKS, CABLING, FACILITIES AND EQUIPMENT THAT IS NOT IN OUR
            CONTROL; ACCORDINGLY (I) WE CAN NOT GUARANTEE ANY MINIMUM LEVEL
            REGARDING SUCH PERFORMANCE, SPEED, RELIABILITY, AVAILABILITY, USE OR
            CONSISTENCY, AND (II) DATA, MESSAGES, INFORMATION OR MATERIALS SENT
            OVER THE INTERNET MAY NOT BE COMPLETELY PRIVATE, AND YOUR ANONYMITY
            IS NOT GUARANTEED.
          </p>
          <br />
          <p><strong>LIMITATION OF LIABILITY</strong></p>
          <br />

          <p>
            NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR
            LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE
            LAW. THIS INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY
            OUR NEGLIGENCE OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR
            SUBCONTRACTORS AND FOR FRAUD OR FRAUDULENT MISREPRESENTATION.
          </p>
          <p>
            SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:
            (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY);
            (II) ANY LOSS OF GOODWILL; (III) ANY LOSS OF OPPORTUNITY; (IV) ANY
            LOSS OF DATA SUFFERED BY YOU; OR (V) ANY INDIRECT OR CONSEQUENTIAL
            LOSSES WHICH MAY BE INCURRED BY YOU. ANY OTHER LOSS WILL BE LIMITED
            TO THE AMOUNT PAID BY YOU TO FunCat WITHIN THE LAST 12 MONTHS.
          </p>
          <p>
            WE WILL ALSO NOT BE LIABLE TO YOU FOR ANY LOSS OR DAMAGE WHICH MAY
            BE INCURRED BY YOU AS A RESULT OF:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR
                EXISTENCE OF ANY ADVERTISING, OR ANY RELATIONSHIP OR TRANSACTION
                BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING
                APPEARS ON THE SERVICE;
              </p>
            </li>
            <li>
              <p>
                ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY
                PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE
                SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
              </p>
            </li>
            <li>
              <p>
                THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT
                AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR
                THROUGH YOUR USE OF THE SERVICES;
              </p>
            </li>
            <li>
              <p>
                YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION;
              </p>
            </li>
            <li>
              <p>
                OR YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE
                AND CONFIDENTIAL.
              </p>
            </li>
          </ul>
          <p>
            PLEASE NOTE THAT WE ONLY PROVIDE OUR SERVICES FOR PERSONAL AND
            PRIVATE USE. YOU AGREE NOT TO USE OUR SERVICES FOR ANY COMMERCIAL OR
            BUSINESS PURPOSES, AND WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF
            PROFIT, LOSS OF BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION,
            BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY.
          </p>
          <p>
            THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT
            WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY
            OF ANY SUCH LOSSES ARISING.
          </p>
          <p>
            YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR
            USE OF OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF
            YOU’RE UNSURE WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE
            PROVIDER BEFORE USING THE SERVICE.
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
            ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING,
            BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER
            OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU
            IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS,
            DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND
            NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH SUCH DISPUTES.
          </p>
          <br />
          <p><strong>Privacy Policy</strong></p>
          <br />
          <p>
            You also acknowledge and agree to (to the extent allowed by
            applicable laws) our Privacy Policy, which also applies to your use
            of our Services. For information on how we may use your personal
            data when you use the Services, please refer to our Privacy Policy.
            Our Privacy Policy can be found directly on the Services, or where
            the Game is made available for download, on your mobile device’s
            applicable app store, and are incorporated herein by reference. By
            accepting these Terms or accessing the Services or Game, you hereby
            consent to our Privacy Policy (to the extent allowed by applicable
            laws).
          </p>
          <p>
            FunCat (Hong Kong) Limited determines the purpose and means of
            processing your personal data.
          </p>
          <br />
          <p><strong>Copyright Claims</strong></p>
          <br />

          <p>
            If you believe that anything on the Service infringes upon any
            copyright which you own or control, you may file a notification of
            such infringement to the contact information set below:
          </p>
          <p>cs@funcatgames.com</p>
          <br />
          <p><strong>Your Access to and Use of Our Services</strong></p>
          <br />

          <p>
            Your access to and use of the Services is subject to these Terms and
            all applicable laws and regulations. You may not:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                access or use the Services in a manner that violates applicable
                laws and regulations, including anti-money laundering and
                counter terrorist financing laws and export control and economic
                sanctions laws. Consistent with and in furtherance of this
                requirement, you agree that you will not export or re-export,
                directly or indirectly the Services and/or other information or
                materials provided by FunCat hereunder, to any country or
                territory for which any other relevant jurisdiction requires any
                export license or other governmental approval at the time of
                export or re-export without first obtaining such license or
                approval. You further agree not to export, re-export, or provide
                any of the Services in violation of applicable export controls
                or economic sanctions (a) into any country or territory against
                which comprehensive sanctions are imposed, administered,
                implemented and/or enforced by any relevant government authority
                or regulatory body, including Cuba, Crimea, Iran, North Korea,
                and Syria, or (b) to anyone listed on any list of prohibited or
                restricted parties issued and maintained by any relevant
                government authority or regulatory body, including but not
                limited to the United Nations Security Council Consolidated
                Sanctions List, the EU Consolidated Financial Sanctions List,
                and the U.S. Treasury Department&#39;s List of Specially
                Designated Nationals and Blocked Persons List. At our sole
                discretion, we reserve the right to immediately suspend a
                user&#39;s use of the Services and/or terminate a user&#39;s
                account if we suspect money laundering, terrorist financing,
                activities that violate economic sanctions or export control
                laws, or other illegal activity by the user. We will not be
                liable for any loss or damage incurred by a user as a result of
                such suspension of the user&#39;s Services and/or termination of
                the user&#39;s account.
              </p>
            </li>
          </ul>

          <br />
          <p><strong>Other Terms</strong></p>
          <br />

          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                Applicable Law and Jurisdiction. Subject to the
                <em>Supplemental Terms – Jurisdiction-Specific</em>, these
                Terms, their subject matter and their formation, are governed by
                the laws of Hong Kong. Any dispute arising out of or relating to
                these Terms or prior versions thereof, including any question
                regarding existence, breach, interpretation, validity,
                enforcement, or termination of these Terms; the Services; the
                Game; FunCat Content; your relationship with us; the threatened
                or actual termination of your access to the Services or Game
                payments made to you or payments made or allegedly owed to you;
                or any other claims (collectively, &quot;Covered Matters&quot;)
                shall be referred to and finally resolved by arbitration
                administered by the Hong Kong International Arbitration Centre
                (&quot;HKIAC&quot;) in accordance with the Arbitration Rules of
                the HKIAC for the time being in force, which rules are deemed to
                be incorporated by reference in this clause. The seat of the
                arbitration shall be Hong Kong. The tribunal shall consist of
                three (3) arbitrators. The language of the arbitration shall be
                English. Notwithstanding the foregoing, we may institute a
                proceeding for equitable relief in any court of competent
                jurisdiction.
              </p>
            </li>
            <li>
              <p>
                Class Action Waiver. Subject to the
                <em>Supplemental Terms –Jurisdiction-Specific,</em> any action
                brought by you, including without limitation arbitration, will
                proceed solely on an individual basis without the right for any
                claim to be adjudicated on a class action basis or on any basis
                involving claims brought in a purported representative capacity
                on behalf of others.
              </p>
            </li>
            <li>
              <p>
                Entire Agreement. These Terms (including the Supplemental Terms
                below) constitute the whole legal agreement between you and
                FunCat and govern your use of the Services and completely
                replace any prior agreements between you and FunCat in relation
                to the Services.
              </p>
            </li>
            <li>
              <p>
                Links. You may link to our home page or page of the Game or
                Services, provided you do so in a way that is fair and legal and
                does not damage our reputation or take advantage of it. You must
                not establish a link in such a way as to suggest any form of
                association, approval or endorsement on our part where none
                exists. You must not establish a link to our Services in any
                website that is not owned by you. The website in which you are
                linking must comply in all respects with the content standards
                set out at &quot;Code of Conduct&quot; above. We reserve the
                right to withdraw linking permission without notice.
              </p>
            </li>
            <li>
              <p>
                No Waiver. Our failure to insist upon or enforce any provision
                of these Terms shall not be construed as a waiver of any
                provision or right.
              </p>
            </li>
            <li>
              <p>
                Security. We do not guarantee that our Services will be secure
                or free from bugs or viruses. You are responsible for
                configuring your information technology, computer programs and
                Game to access our Services. You should use your own virus
                protection software.
              </p>
            </li>
            <li>
              <p>
                Severability. If any court of law, having jurisdiction to decide
                on this matter, rules that any provision of these Terms is
                invalid, then that provision will be removed from the Terms
                without affecting the rest of the Terms, and the remaining
                provisions of the Terms will continue to be valid and
                enforceable.
              </p>
            </li>
            <li>
              <p>
                Open Source. This game may contain certain open source software.
                Each item of open source software is subject to its own
                applicable license terms.
              </p>
            </li>
            <li>
              <p>
                Translations. In the event of any conflict or inconsistency
                between the Terms in the English and any translation thereof in
                any other language, the English version shall prevail.
              </p>
            </li>
            <li>
              <p>
                Any Questions? Need help with the Game? Get in touch at
                cs@funcatgames.com.
              </p>
            </li>
          </ul>
          <br />
          <p><strong> Supplemental Terms – App Stores</strong></p>
          <br />

          <p>
            The following supplemental terms shall apply when accessing the Game
            through specific devices:
          </p>
          <br />
          <p>
            <strong> <em>Notice regarding Apple.</em></strong>
          </p>
          <br />

          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                These Terms are a binding agreement between FunCat and you;
                Apple is not a party to these Terms.
              </p>
            </li>
            <li>
              <p>
                The license granted to you hereunder is limited to a personal,
                limited, non-exclusive, non-transferable right to install the
                Game on the Apple device(s) authorized by Apple that you own or
                control for personal, non-commercial use, subject to the Usage
                Rules set forth in Apple’s App Store Terms of Services.
              </p>
            </li>
            <li>
              <p>
                Apple is not responsible for the Game or the content thereof and
                has no obligation whatsoever to furnish any maintenance or
                support services with respect to the Game.
              </p>
            </li>
            <li>
              <p>
                In the event of any failure of the Game to conform to any
                applicable warranty, you may notify Apple, and Apple will refund
                the purchase price for the Game, if any, to you. To the maximum
                extent permitted by applicable law, Apple will have no other
                warranty obligation whatsoever with respect to the Game.
              </p>
            </li>
            <li>
              <p>
                Apple is not responsible for addressing any claims by you or a
                third party relating to the Game or your possession or use of
                the Game, including without limitation (a) product liability
                claims; (b) any claim that the Game fails to conform to any
                applicable legal or regulatory requirement; and (c) claims
                arising under consumer protection or similar legislation.
              </p>
            </li>
            <li>
              <p>
                In the event of any third party claim that the Game or your
                possession and use of the Game infringes such third party’s
                intellectual property rights, Apple is not responsible for the
                investigation, defense, settlement or discharge of such
                intellectual property infringement claim.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant that (a) you are not located in a
                country that is subject to a U.S. Government embargo, or that
                has been designated by the U.S. Government as a &quot;terrorist
                supporting&quot; country; and (b) you are not listed on any U.S.
                Government list of prohibited or restricted parties.
              </p>
            </li>
            <li>
              <p>
                Apple and its subsidiaries are third party beneficiaries of
                these Terms and upon your acceptance of the terms and conditions
                of these Terms, Apple will have the right (and will be deemed to
                have accepted the right) to enforce these Terms against you as a
                third party beneficiary hereof.
              </p>
            </li>
            <li>
              <p>
                FunCat expressly authorizes use of the Game by multiple users
                through the family sharing or any similar functionality provided
                by Apple.
              </p>
            </li>
          </ul>
          <br />
          <p><strong> Notice regarding Google Play</strong></p>
          <br />

          <p>
            By downloading the Game from Google Play (or its successors)
            operated by Google, Inc. or one of its affiliates
            (&quot;Google&quot;), you specifically acknowledge and agree that:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                to the extent of any conflict between (a) the Google Play Terms
                of Services and the Google Play Business and Program Policies or
                such other terms which Google designates as default end user
                license terms for Google Play (all of which together are
                referred to as the &quot;Google Play Terms&quot;), and (b) the
                other terms and conditions in these Terms, the Google Play Terms
                shall apply with respect to your use of the Game that you
                download from Google Play, and
              </p>
            </li>
            <li>
              <p>
                you hereby acknowledge that Google does not have any
                responsibility or liability related to compliance or
                non-compliance by FunCat or you (or any other user) under these
                Terms or the Google Play Terms.
              </p>
            </li>
          </ul>
          <br />
          <p><strong> Supplemental Terms – Jurisdiction-Specific</strong></p>

          <p><strong> European Union and also the UK</strong></p>

          <br />

          <p><strong> Age and Accepting the Terms</strong></p>
<br>
          <p>
            By clicking on the &quot;Install&quot; button on the product
            description page of the respective app store (caption may vary
            depending on the app store) and, if applicable, entering your
            password or other credentials for the respective app store, you make
            a binding offer to conclude a contract for the use of the Game,
            including the granting of rights of use in accordance with these
            Terms. We accept your offer when the download of the Game starts;
            further details may be governed by the terms and conditions of the
            respective app store.
          </p>
          <p>
            If you are under the legal age to enter into a contract in your
            jurisdiction, your parents or legal guardian must consent to your
            acceptance of these Terms. If we learn that someone under the
            relevant age is using the Services, we will terminate access to the
            Service for such user.
          </p>
          <br />

          <p><strong> Privacy Policy</strong></p>
<br>
          <p>
            For information on how we use your personal data when you use the
            Services, please refer to our Privacy Policy. Privacy Policy can be
            found directly on the Services, or where the Game is made available
            for download, on your mobile device’s applicable app store.
          </p>
          <br />

          <p><strong>Cancellation Right</strong></p>
<br>
          <p>
            If you are a consumer and resident of the UK or EU, you have a
            mandatory cancellation right when purchasing digital content (e.g
            Virtual Items) as follows (but please note you will lose this
            cancellation right if you provide prior express consent to begin the
            download of that content during the right of cancellation period,
            and acknowledge that you will lose your cancellation right):
          </p>
          <br />
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                <strong>Cancellation right</strong>
              </p>
            </li>
            <ul
              class="list-bullet2 list-paddingleft-2"
              style="list-style-type: square"
            >
			<br>
              <li>
                <p>
                  You have the right to cancel a contract within 14 days without
                  giving any reason.
                </p>
              </li>
              <li>
                <p>
                  The cancellation period will expire after 14 days from the day
                  of the conclusion of the contract.
                </p>
              </li>
              <li>
                <p>
                  To exercise the right of withdrawal, you must inform us (Email
                  Address: &nbsp;cs@funcatgames.com) of your decision to
                  withdraw from this contract by an unequivocal statement (e.g.
                  a letter sent by post or e-mail). You may use the attached
                  model withdrawal form, but it is not obligatory.
                </p>
              </li>
              <li>
                <p>
                  To meet the cancellation deadline, it is sufficient for you to
                  send your communication concerning your exercise of the right
                  of withdrawal before the withdrawal period has expired.
                </p>
              </li>
            </ul>
            <br />


            <li>
              <p>
                <strong>Effects of cancellation</strong>
              </p>
            </li>
          </ul>
<br>
          <p>
            If you cancel a contract, we shall reimburse to you all payments
            received from you without undue delay and in any event not later
            than 14 days from the day on which we are informed about your
            decision to withdraw from this contract. We will carry out such
            reimbursement using the same means of payment as you used for the
            initial transaction, unless you have expressly agreed otherwise; in
            any event, you will not incur any fees as a result of such
            reimbursement.
          </p>
          <p>
            <em>End of cancellation notice.</em>
          </p>
          <br />

          <p>
            <strong>Model cancellation form</strong>
          </p>
<br>
          <p>
            (If you wish to cancel the contract, please complete this form and
            return it to us)
          </p>
          <p>To</p>
          <p>FunCat Games Limited</p>
          <p>Email Address: cs@funcatgames.com</p>
          <p>
            I/We (*) hereby give notice that I/We (*) cancel my/our (*) contract
            for the supply of the following digital content (*) ______________
          </p>
          <p>Ordered on (*)/received on (*),</p>
          <p>________________________</p>
          <p>Name of consumer(s),</p>
          <p>________________________</p>
          <p>Address of consumer(s),</p>
          <p>________________________</p>
          <p>
            Signature of consumer(s) (only if this form is notified on paper)
          </p>
          <p>________________________</p>
          <p>
            <em>Date, Location</em>
          </p>
          <p>(*) Delete as appropriate</p>
          <br />
          <p>
            <strong> Germany</strong>
          </p>
<br>
          <p>
            The following terms apply in addition to the European Union terms
            above.
          </p>

          <br>
          <p>
                <strong> Changes of Terms</strong>
              </p>
<br>      
          <p>
            In deviation from Sec. 4 of the Terms of Service, the following
            applies:
          </p>
          <p>
            We may amend the Terms in accordance with this paragraph to the
            extent that this is done (i) to implement changes in legal
            requirements or case law, (ii) to implement changes in technical
            requirements, (iii) to maintain the operation of the Services, (iv)
            to adapt to changing market conditions, and (v) for the benefit of
            the user. An adjustment will only be made to the extent that it does
            not shift the contractual balance between us to your detriment. We
            will inform you of an adjustment at least six weeks in advance by
            means of a message within the game or by e-mail. You may object to
            the adjustment. If you do not object within six weeks after receipt
            of the notification of the adjustment, you are deemed to have
            consented to the change. In our notification of the adaptation, we
            will also inform you separately about the six-week period and the
            legal consequences of your silence.
          </p>

            <br>
          <p>
                <strong> Refunds</strong>
              </p>
 <br>     
          <p>
            In deviation from Sec. 6 and 7 of the Terms of Service, we will
            refund any purchased but unused Virtual Items in the event (i) we
            terminate the agreement for convenience or (ii) you terminate the
            agreement because of our breach of the agreement. We will also make
            refunds if required in the context of your statutory withdrawal
            right or your warranty rights (see Sec. 6 of the
            jurisdiction-specific terms for Germany below).
          </p>

               <br>
          <p>
                <strong>Termination</strong>
              </p>
<br>       
          <p>
            In deviation from Sec. 11 and 12 of the Terms of Service, we may
            terminate the Services (including the Game) for convenience with 14
            days’ notice, and the statutory right of extraordinary termination
            for cause remains unaffected.
          </p>
               <br>
          <p>
                <strong>Reverse Engineering</strong>
              </p>
   <br>  
          <p>
            In deviation from the second Bullet Point of Sec. 12 of the Terms of
            Service, you may decompile the Services to the extent required to
            make contractual use of them (including troubleshooting) and/or to
            the extent required to obtain interface information needed to make
            the Service interoperable with an independently developed piece of
            software, and we have not provided you the required information upon
            your request against payment of our expenses in providing such
            information. For clarity, in the event of any such permitted
            decompilation, the statutory restrictions of Sec 69e of the German
            Copyright Act apply.
          </p>
         
            <li>
   <br>
          <p>
                <strong>Indemnity</strong>
              </p>
<br>
             
            </li>
  
          <p>
            In deviation from Sec. 13 of the Terms of Service, your obligation
            to indemnify applies only in the event of your culpable (negligent
            or intentional) breach of the Terms of Service.
          </p>

            <br>
          <p>
                <strong>Warranty</strong>
              </p>
 <br>      
          <p>
            In deviation from Sec. 14 of the Terms of Service, the following
            applies:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                To the extent we provide any Service free of charge, we do not
                assume any warranty except where we have maliciously concealed a
                defect.
              </p>
            </li>
            <li>
              <p>
                To the extent we provide a Service against a charge, our
                statutory warranty obligations remain unaffected, except that we
                are liable for damages only pursuant to Sec. 7 of the
                jurisdiction-specific terms for Germany.
              </p>
            </li>
          </ul>

          
            <br>
          <p>
                <strong>Limitation of Liability</strong>
              </p>
 <br>       
          <p>
            In deviation from &nbsp;Sec. 15 of the Terms of Service, the
            following applies:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                To the extent we provide any Service free of charge, we are
                liable pursuant to applicable law only for intent and gross
                negligence.
              </p>
            </li>
            <li>
              <p>
                To the extent we provide any Service against a charge, our
                liability is limited as follows
              </p>
            </li>
          </ul>
          <p>
            a) Our statutory liability is unlimited for intent, gross
            negligence, injury to life, body or health, violation of a guarantee
            (which must be expressly designated as such), and under the German
            Product Liability Act.
          </p>
          <p>
            b) Except for the cases described in subsection a), we are only be
            liable for slight negligence in case of breach of an essential
            obligation. Essential obligations are those obligations that must be
            performed in order to achieve the purpose of the contract and on the
            performance of which you may therefore generally rely.
          </p>
          <p>
            c) In the cases set forth in subsection b), we are only be liable
            for typical and foreseeable damages.
          </p>
          <p>d) Otherwise, we are not be liable for slight negligence.</p>
          <p>
            e) You must back up your data regularly. To the extent that we are
            liable for a loss of data pursuant to this Sec.7, such liability is
            limited to the restoration effort which would have been required if
            you had regularly made backup copies in accordance with the
            importance of the affected data.
          </p>
          <p>
            f) The above limitations of liability shall apply accordingly in
            favor of our affiliates and our and their shareholders, legal
            representatives, employees, vicarious agents and assistants.
          </p>
               <br>
          <p>
                <strong> Applicable Law and Jurisdiction; Dispute Resolution</strong>
              </p>
  <br>      
       
          <p>
            Our agreement is governed by German law, excluding the CISG. The
            court at your place of residence has jurisdiction for any dispute
            arising out of or in connection with our agreement. You may also
            bring a lawsuit against us in any other competent court (e.g. at our
            seat).
          </p>
          <p>
            The European Commission provides an online dispute resolution
            platform, which you can reach at http://ec.europa.eu/odr. We are not
            obliged or willing to participate in a dispute settlement procedure
            before a consumer arbitration body.
          </p>
                  <br>
          <p>
                <strong> Miscellaneous</strong>
              </p>
      <br>
          <p>
            The languages available to enter into this Agreement are German and
            English. We do not store a copy of our agreement for you to access,
            so we recommend you download or print the Terms for future
            reference.
          </p>
                 <br>
          <p>
                <strong> United Kingdom</strong>
              </p>
  <br>      
          <p>
            The following terms apply in addition to the European Union terms
            above.
          </p>

                <br>
          <p>
                <strong> Indemnity</strong>
              </p>
     <br>  
          <p>Section 13 (indemnity) does not apply to you.</p>

            <br>
          <p>
                <strong>Exclusion of warranties.</strong>
              </p>
  <br>      
          <p>Section 14 (exclusion of warranties) is modified to state:</p>
          <p>
            NOTHING IN THESE TERMS SHALL AFFECT ANY LEGAL RIGHTS THAT YOU ARE
            ALWAYS ENTITLED TO AS A CONSUMER.
          </p>
          <p>
            THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND WE DO NOT MAKE
            PROMISES WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT PROMISE THAT:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</p>
            </li>
            <li>
              <p>
                YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE
                OR FREE FROM ERROR;
              </p>
            </li>
            <li>
              <p>
                ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
                SERVICES WILL BE ACCURATE OR RELIABLE; OR
              </p>
            </li>
            <li>
              <p>
                DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE
                PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
              </p>
            </li>
          </ul>
          <p>
            WE MAY CHANGE, SUSPEND, WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL
            OR ANY PART OF OUR GAME FOR BUSINESS AND OPERATIONAL REASONS AT ANY
            TIME WITHOUT NOTICE.
          </p>
            <br>
          <p>
                <strong>Limitation of Liability</strong>
              </p>
   <br>   
          <p>Section 15 (limitation if liability) is modified to state:</p>
          <p>
            Nothing in these Terms shall exclude or limit our liability for
            death or personal injury caused by our negligence or the negligence
            of our employees, agents or subcontractors and for fraud or
            fraudulent misrepresentation.
          </p>
          <p>
            Subject to the paragraph above, you agree that you will not use our
            Services for any commercial or business purposes, and we have no
            liability to you for any loss of profit, loss of business, business
            interruption, or loss of business opportunity.
          </p>
          <p>
            We will not be liable to you for any loss or damage which may be
            incurred by you as a result of:
          </p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                Any reliance placed by you on the completeness, accuracy or
                existence of any advertising, or as a result of any relationship
                or transaction between you and any advertiser or sponsor whose
                advertising appears on the Service;
              </p>
            </li>
            <li>
              <p>
                The deletion of, corruption of, or failure to store, any content
                and other communications data maintained or transmitted by or
                through your use of the Services;
              </p>
            </li>
            <li>
              <p>
                Your failure to provide us with accurate account information;
              </p>
            </li>
            <li>
              <p>
                Or your failure to keep your password or account details secure
                and confidential.
              </p>
            </li>
          </ul>
          <p>
            If defective digital content that we have supplied damages a device
            or digital content belonging to you and this is caused by our
            failure to use reasonable care and skill, we will either repair the
            damage or pay you compensation. However, we will not be liable for
            damage that you could have avoided by following our advice to apply
            an update offered to you free of charge or for damage that was
            caused by you failing to correctly follow installation instructions
            or to have in place the minimum system requirements advised by us.
          </p>
          <p>
            These limitations on our liability to you shall apply whether or not
            we have been advised of or should have been aware of the possibility
            of any such losses arising.
          </p>
          <p>
            You are responsible for any mobile charges that may apply to your
            use of our Service, including text-messaging and data charges. If
            you’re unsure what those charges may be, you should ask your service
            provider before using the Service.
          </p>
          <p>
            Any dispute you have with any third party arising out of your use of
            the Services, including, by way of example and not limitation, any
            carrier, copyright owner or other user, is directly between you and
            such third party, and you irrevocably release us and our affiliates
            from any and all claims, demands and damages (actual and
            consequential) of every kind and nature, known and unknown, arising
            out of or in any way connected with such disputes.
          </p>
               <br>
          <p>
                <strong>  Other Terms. Applicable Law and Jurisdiction</strong>
              </p>
      <br>
       
          <p>
            Section 19 (Other Terms. Applicable Law and Jurisdiction) is
            modified to state:
          </p>
          <p>
            These Terms, their subject matter and their formation, are governed
            by English law.
          </p>
          <p>
            However, if you are a consumer and resident of any European Union
            country you will benefit from mandatory provisions of, and legal
            rights available to you under, the laws of that country. Nothing in
            these Terms affects your rights as a consumer to rely on these local
            law mandatory provisions and legal rights.
          </p>
          <p>
            You and we both agree that the courts of England and Wales will have
            non-exclusive jurisdiction. However, if you are a consumer and
            resident of any European Union country you and we may also bring
            proceedings in that country.
          </p>
                 <br>
          <p>
                <strong>    AUSTRALIA</strong>
              </p>
    <br>  
    
          <p>
            If you are a resident of Australia, our goods and services come with
            guarantees that cannot be excluded under the Australian Consumer
            Law. In Australia, consumers have statutory rights which cannot be
            excluded under the Australian Consumer Law. Nothing in these Terms
            is intended to exclude, restrict or modify any right or remedy you
            have in statute or otherwise to the extent that right or remedy
            cannot be excluded, restricted or modified under law.
          </p>
          <br>
          <p>
            <strong
              >The first paragraph of Section 7 (Fees and Refund) is modified to
              state:</strong
            >
          </p>
  <br>
          <p>
            You may access certain portions of the Game for free, but you may be
            required to pay a fee to access certain features of the Game. If you
            purchase any paid Services, you will make a purchase through the
            Apple iTunes Store or the Google Play store. You agree to provide
            accurate and complete payment information to Apple or Google. You
            further agree to pay all fees and taxes incurred by your account. We
            reserve the right to change the pricing of any paid Services from
            time to time as we may determine in our sole discretion. All current
            prices will be shown prior to purchase, please carefully check the
            price before making any purchases.
          </p>
          <p>
            The last paragraph of Section 12 (Code of Conduct) is modified to
            state:
          </p>
          <p>
            We reserve the right, at any time, to remove or disable your access
            to the Services, including the Game, FunCat Content and Virtual
            Items with immediate effect, at our discretion, for your breach of
            law, or any of these Terms. We will notify you of such removal or
            disabling. Some of the reasons for which we may remove or disable
            access to your Services may include finding that you have violated
            these Terms, or that your actions are harmful to the Services or our
            users.
          </p>
          <br>

          <p>
            <strong>Section 13 (Indemnity) is modified to state:</strong>
          </p>
<br>
          <p>
            You agree to, at your sole cost, defend, indemnify, and hold
            harmless FunCat, its parents, subsidiaries, and affiliates, and each
            of their respective officers, directors, employees, agents,
            suppliers and advisors from and against any and all claims,
            liabilities, costs, fines, penalties and expenses, including, but
            not limited to, attorneys’ fees and expenses, arising out of or in
            any way connected with any of the following: (i) a breach by you or
            any user of your account of any applicable obligation,
            representation or warranty under these Terms; (ii) your violation of
            any applicable laws, rules, regulations or contracts; or (iii) any
            misrepresentation made by you (all of the foregoing, &quot;Claims
            and Losses&quot;). You will cooperate as fully required by us in the
            defense of any Claims and Losses. Notwithstanding the foregoing, we
            retain the exclusive right to settle, compromise, and pay any and
            all Claims and Losses. We reserve the right to assume the exclusive
            defense and control of any Claims and Losses at our own cost. You
            will not settle any Claims and Losses without, in each instance, the
            prior written consent of an officer of the indemnified party. Your
            indemnification obligations above do not apply to the extent the
            Claims and Losses are caused by a breach of the Terms by, or the
            fraudulent or negligent act or omission of, FunCat.
          </p>
          <br>
 <p>
            <strong> CANADA</strong>
          </p>
        
          <p>
            <strong>Section 18 (Other Terms) is modified to state:</strong>
          </p>
<br>
          <p>
            Applicable Law and Jurisdiction. These Terms, their subject matter
            and their formation, are governed by the laws of the Province of
            Ontario and the federal laws of Canada applicable therein. Any
            dispute arising out of or in connection with these Terms, including
            any question regarding existence, validity or termination of these
            Terms, shall be referred to and finally resolved by arbitration
            administered by the International Centre for Dispute Resolution
            Canada in accordance with its Canadian Arbitration Rules. The place
            of the arbitration shall be Toronto, Ontario, Canada. The tribunal
            shall consist of three (3) arbitrators. The language of the
            arbitration shall be English. Notwithstanding the foregoing, we may
            institute a proceeding for equitable relief in any court of
            competent jurisdiction.
          </p>
          <p>
            The provision in Section 18 (Other Terms) captioned &quot;Class
            Action Waiver&quot; is deleted in its entirety.
          </p>
          <p>
            The provision in Section 18 (Other Terms) captioned
            &quot;Translations&quot; does not apply in the Province of Quebec.
          </p>

             <br>
 <p>
            <strong>   NEW ZEALAND</strong>
          </p>
<br>    
          <p>
            You must be at least 13 years of age to access or use the Services.
            If you are over 13 years of age but under 18 years of age, your
            parents or legal guardian must read and agree to these Terms.
          </p>
          <p>
            If you are a &quot;consumer&quot; for the purposes of the Fair
            Trading Act 1986 and Consumer Guarantees Act 1993, nothing in these
            Terms operates to exclude, restrict or modify the application of any
            implied condition or warranty, provision, the exercise of any right
            or remedy, or the imposition of any liability under the Fair Trading
            Act 1986 or the Consumer Guarantees Act 1993.
          </p>
          <p>
            For the purposes of Sections 5, 8, 11 and 12, if we wish to suspend
            or terminate your access, or licence to use, the Services (other
            than because you have failed to comply with any of the provisions of
            these Terms, or if activities occur on your account which, in our
            sole discretion, would or might cause damage to or impair the
            Services or infringe or violate any third party rights (including
            intellectual property rights), or violate any applicable laws or
            regulations), we will give you one months’ notice prior to such
            suspension or termination.
          </p>
          <p>
            For the purposes of Section 7, if we wish to vary the pricing of any
            paid Services we will give you prior notice before doing so. All
            current prices will be shown prior to purchase, please carefully
            check the price before making any purchases.
          </p>

             <br>
 <p>
            <strong>      UNITED STATES</strong>
          </p>
 <br>   
        
          <p>Section 18 “Other Terms” above is modified as follows:</p>
          <ul class="list-bullet1 list-paddingleft-2">
            <li>
              <p>
                This section replaces the “Applicable Law and Jurisdiction”
                portion of Section 18 above:
              </p>
            </li>
            <li>
              <p>
                Applicable Law and Jurisdiction. These Terms, their subject
                matter and their formation, are governed by the laws of the
                United States and of the State of California. Notwithstanding
                the foregoing, we may institute a proceeding for equitable
                relief in any court of competent jurisdiction.
              </p>
            </li>
            <li>
              <p>
                Agreement to Arbitration and Class Action Waiver. This section
                includes an agreement to arbitrate and an agreement that any
                dispute arising out of or relating to these Terms or prior
                versions thereof, including any question regarding existence,
                breach, interpretation, validity, enforcement, or termination of
                these Terms; the Services; the Game; FunCat Content; your
                relationship with us; the threatened or actual termination of
                your access to the Services or Game payments made to you or
                payments made or allegedly owed to you; or any other claims
                (collectively, “Covered Matters”) will be brought in arbitration
                only in your individual capacity (and not as a class action or
                other representative proceeding). Please read it carefully. You
                may opt out of the arbitration agreement by following the opt
                out procedure described below.
              </p>
            </li>
            <li>
              <p>
                Informal Process First. You agree that in the event of any
                dispute over any Covered Matters between you and FunCat, you
                will first contact FunCat and make a good faith sustained effort
                to resolve the dispute before resorting to more formal means of
                resolution, including without limitation any court action. This
                is a condition precedent to proceeding in arbitration. To
                initiate the informal process, you must contact us at
                cs@funcatgames.com , and include your contact information and a
                written description of the dispute in the email. You must then
                allow sixty (60) days to pass, during which we will attempt to
                reach an amicable resolution of your issue.
              </p>
            </li>
            <li>
              <p>
                Arbitration Agreement. After the informal dispute resolution
                process, any remaining dispute, controversy, or claim
                (collectively, “Claim”) arising out of or relating in any way to
                any Covered Matters, will be finally resolved by binding
                arbitration, and not any federal, state, or local court or
                agency, except as expressly provided herein.
              </p>
            </li>
          </ul>
          <p>
            YOU UNDERSTAND, ACKNOWLEDGE, AND AGREE THAT YOU AND FunCat ARE EACH
            WAIVING THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL FOR ANY AND
            ALL COVERED MATTERS, EXCEPT AS EXPRESSLY PROVIDED IN THIS
            ARBITRATION AGREEMENT.
          </p>
          <p>
            This arbitration agreement applies equally to you and FunCat.
            However, this arbitration agreement does not (a) govern any Claim by
            FunCat for infringement of its intellectual property or access to
            the Services that is unauthorized or exceeds authorization granted
            in these Terms or (b) bar you from making use of applicable small
            claims court procedures if the Claim and the parties are within the
            jurisdiction of such small claims court. If you are an individual,
            you may opt out of this arbitration agreement within thirty (30)
            days of the first of the date you access or use the Services by
            sending an email to cs@funcatgames.com with the subject line
            “Arbitration Opt-Out.”
          </p>
          <p>
            You agree that the U.S. Federal Arbitration Act governs the
            interpretation and enforcement of this Arbitration Agreement, and
            that you and FunCat are each waiving the right to a trial by jury or
            to participate in a class action. This Arbitration Agreement will
            survive any termination of these Terms.
          </p>
          <p>
            If you wish to begin an arbitration proceeding, after following the
            informal dispute resolution procedure, you must send a letter
            requesting arbitration and describing your claim to:
          </p>
          <p>Email Address: cs@funcatgames.com</p>
          <p>
            The arbitration will be administered by the American Arbitration
            Association (AAA) under its rules including, if you are an
            individual, the AAA&#39;s Supplementary Procedures for
            Consumer-Related Disputes. If you are not an individual or have used
            the Services on behalf of an entity, the AAA&#39;s Supplementary
            Procedures for Consumer-Related Disputes will not be used. The
            AAA&#39;s rules are available at www.adr.org or by calling
            1-800-778-7879.
          </p>
          <p>
            Payment of all filing, administration and arbitrator fees will be
            governed by the AAA&#39;s rules. If you are an individual and have
            not accessed or used the Services on behalf of an entity, we will
            reimburse those fees for claims where the amount in dispute is less
            than $10,000, unless the arbitrator determines the claims are
            frivolous, and we will not seek attorneys’ fees and costs in
            arbitration unless the arbitrator determines the claims are
            frivolous.
          </p>
          <p>
            The arbitrator, and not any federal, state, or local court, will
            have exclusive authority to resolve any dispute relating to the
            interpretation, applicability, unconscionability, arbitrability,
            enforceability, or formation of this Arbitration Agreement,
            including any claim that all or any part of this Arbitration
            Agreement is void or voidable. However, the preceding sentence will
            not apply to the “Class Action Waiver” section below.
          </p>
          <p>
            This section replaces the “Class Action Waiver” portion of Section
            18 above:
          </p>
          <p>
            Class Action Waiver. You acknowledge and agree that any Claim must
            be brought in the respective party’s individual capacity, and not as
            a plaintiff or class member in any purported class, collective,
            representative, multiple plaintiff, or similar proceeding (“Class
            Action”) except that an individual may seek in the arbitration
            public injunctive relief, and the AAA may include such relief in the
            award, where applicable. You and FunCat mutually and expressly waive
            any ability to maintain any Class Action in any forum. If the Claim
            is subject to arbitration, the arbitrator will not have authority to
            combine or aggregate similar claims or conduct any Class Action nor
            make an award to any person or entity not a party to the
            arbitration. Any claim that all or part of this Class Action Waiver
            is unenforceable, unconscionable, void, or voidable may be
            determined only by a court of competent jurisdiction and not by an
            arbitrator.
          </p>
          <p>
            If this Class Action Waiver is found to be unenforceable such that
            certain Covered Matters must proceed on a class, collective,
            consolidated, or representative basis, such Covered Matters must be
            litigated in state or federal civil court of competent jurisdiction,
            not in arbitration. You and FunCat further agree that such Covered
            Matters will be stayed pending the outcome of any individual claims
            in arbitration between you and FunCat. If for any reason a claim
            proceeds in court rather than in arbitration, you and FunCat each
            waive any right to a jury trial.
          </p>
          <br>
          <p>
            <strong>This section is amended to Section 18 above:</strong>
          </p>
<br>		  
          <p>
            Exports. You agree that you will not export or re-export, directly
            or indirectly the Services and/or other information or materials
            provided by FunCat hereunder, to any country for which the United
            States or any other relevant jurisdiction requires any export
            license or other governmental approval at the time of export without
            first obtaining such license or approval. In particular, but without
            limitation, the Services may not be exported or re-exported (a) into
            any U.S. comprehensively embargoed countries or any country that has
            been designated by the U.S. Government as a “terrorist supporting”
            country (currently: Cuba, Crimea, Iran, North Korea, Syria), (b) to
            any governments of such countries, or (c) to anyone listed on any
            U.S. Government list of prohibited or restricted parties, including
            the U.S. Treasury Department&#39;s list of Specially Designated
            Nationals or the U.S. Department of Commerce Denied Person’s List or
            Entity List.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>FunCat All rights reserved.</strong>
          </p>
          <p>
            <span class="lark-record-clipboard"></span>
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      title: "",
    };
  },
  methods: {
    async getPrivacy() {
      await this.$store.dispatch("getPrivacy");
      this.content = this.$store.state.privacyData.Terms.content;
      this.title = this.$store.state.privacyData.Terms.title;
    },
  },
  mounted() {
    this.getPrivacy();
    // console.log(this.$store.state.privacyData);
  },
};
</script>

<style scoped="scoped">
.product {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  /* margin-top: -3%; */
  max-width: 1240px;
}
.product-list {
  padding: 2%;
  background-color: white;
}
.product-list:nth-of-type(1) {
  margin-bottom: 2%;
}
.title {
  font-size: 24px;
  font-weight: 600;
  color: rgb(184, 37, 42);
  padding: 1% 0 1% 0;
  border-bottom: 2px solid rgb(184, 37, 42);
}

.product-text {
  font-size: 20px;
}
</style>
