<template>
  <div id="app">
        <router-view/>
        
        
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  // Index
  },


  mounted(){
  
   this.handleResize()
     window.addEventListener('resize', this.handleResize);
  },
   methods: {
    handleResize() {
      if (window.innerWidth <= 626) {
        document.body.style.zoom = window.innerWidth / 626;
      } else if(window.innerWidth > 626 && window.innerWidth<=980) {
  document.body.style.zoom=1
      }else{
document.body.style.zoom = window.innerWidth / 1920;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
  @media screen and (max-width: 980px){
  #app{
      max-width: 626px;
      margin: 0 auto;
      
    }
    }
</style>
